:root {
  --fuscous-gray: #3e3b36;
  --indian-khaki: #c0aa82;
  --wild-blue: #7991B2;
  --rock-blue: #97B4CD;
}

.sellables_container {
  background-color: var(--fuscous-gray);
  min-height: inherit;
}

.item_img:hover {
  transform: scale(1.025);
}

.button {
  background-color: #D8CCB6;
  color: #3B3B3B;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
